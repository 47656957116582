<template>
  <v-dialog
    v-model="showDialog"
    :max-width="700"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          <span class="text-capitalize">{{ updatedRequest.action_type }} Request Detail</span>
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <template>
          <div class="mt-5">
            <div
              v-if="selectedRequest.status === CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.CREATED"
              v-feature="CONSTS.CAPTABLE_FEATURES.SERVICE_REQUESTS_ACTIONS"
              class="float-right px-5"
            >
              <request-action-reason-modal
                :reason-type="CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED"
                @confirm="confirm"
              >
                <template v-slot:activator="{ on }">
                  <common-trs-btn
                    aria-label="Reject"
                    type="secondary"
                    class="rounded-0 mr-2"
                    color="primary"
                    size="large"
                    v-on="{...on}"
                    @click="$emit('reject')"
                  >
                    Reject
                  </common-trs-btn>
                </template>
              </request-action-reason-modal>
              <request-action-reason-modal
                :reason-type="CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.APPROVED"
                @confirm="confirm"
              >
                <template v-slot:activator="{ on }">
                  <common-trs-btn
                    aria-label="Approve"
                    type="primary"
                    class="rounded-0 text-capitalize"
                    color="primary"
                    size="medium"
                    v-on="{...on}"
                    @click="$emit('approve')"
                  >
                    Approve
                  </common-trs-btn>
                </template>
              </request-action-reason-modal>
            </div>
            <details-table
              :share-items="updatedRequest"
              :title="''"
            />
          </div>
        </template>
      </common-trs-modal-card-text>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  import DetailsTable from '@/views/dashboard/holders/components/DetailsTable'
  import RequestActionReasonModal from './RequestActionReasonModal'

  export default {
    name: 'RequestDetailModal',

    components: {
      DetailsTable,
      RequestActionReasonModal,
    },

    props: {
      selectedRequest: {
        type: Object,
        default: () => {},
        required: true,
      },
    },

    data: () => ({
      showDialog: false,
      updatedRequest: {},
    }),

    watch: {
      selectedRequest: {
        handler (val) {
          if (val && Object.keys(val)?.length > 0) {
            this.updateRequestData(val)
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateRequestData (request) {
        this.updatedRequest.captable_SRID = request?.captable_srid.toString() || ''
        this.updatedRequest.security_type = request?.security_type || ''
        this.updatedRequest.action_type = request?.action_type || ''
        this.updatedRequest.status = request?.status || ''
        this.updatedRequest.registration_name = request?.details?.payload?.registration_name || request?.details?.certificatePayload?.registrationName || ''
        if (request?.details?.certificatePayload?.issuedNumber) {
          this.updatedRequest[`no_of_${this.updatedRequest.security_type}`] = this.$number(request?.details?.certificatePayload?.issuedNumber) || ''
        }
        if (request?.details?.certificatePayload?.amount) {
          this.updatedRequest.amount = this.$currencyWithPrecision(request?.details?.certificatePayload?.amount) || ''
        }
        if (request?.details?.reason) {
          this.updatedRequest.reason = request.details.reason
        }
        this.updatedRequest.requested_by = request.created_by
        this.updatedRequest.requested_on = this.$date(request.created_on)
      },

      close () {
        this.showDialog = false
        this.$emit('close')
      },

      confirm () {
        this.$emit('updateReason')
        this.close()
      },
    },
  }
</script>
