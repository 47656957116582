<template>
  <div
    style="width: 50px;"
    class="text-center"
  >
    <v-menu
      ref="menu"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <common-trs-btn
          light
          text
          type="secondary"
          class="mx-1 rounded-0"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="black--text">
            mdi-dots-vertical
          </v-icon>
        </common-trs-btn>
      </template>
      <v-list>
        <v-list-item v-feature="CONSTS.CAPTABLE_FEATURES.VIEW_SERVICE_REQUESTS">
          <v-list-item-title>
            <request-detail-modal
              :show-dialog="showDialog"
              :selected-request="selectedRequest"
              @close="closeMenu"
              @approve="$emit('selectRequest', selectedRequest, CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.APPROVED)"
              @reject="$emit('selectRequest', selectedRequest, CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED)"
              @updateReason="updateReason"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{ ...on}"
                >
                  View
                </a>
              </template>
            </request-detail-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="selectedRequest.status === CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.CREATED"
          v-feature="CONSTS.CAPTABLE_FEATURES.SERVICE_REQUESTS_ACTIONS"
        >
          <v-list-item-title>
            <request-action-reason-modal
              :reason-type="CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.APPROVED"
              @confirm="updateReason"
              @close="closeMenu"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{...on}"
                  @click="$emit('selectRequest', selectedRequest, CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.APPROVED)"
                >
                  Approve
                </a>
              </template>
            </request-action-reason-modal>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="selectedRequest.status === CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.CREATED"
          v-feature="CONSTS.CAPTABLE_FEATURES.SERVICE_REQUESTS_ACTIONS"
        >
          <v-list-item-title>
            <request-action-reason-modal
              :reason-type="CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED"
              @confirm="updateReason"
            >
              <template v-slot:activator="{ on }">
                <a
                  v-on="{...on}"
                  @click="$emit('selectRequest', selectedRequest, CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED)"
                >
                  Reject
                </a>
              </template>
            </request-action-reason-modal>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import RequestDetailModal from './RequestDetailModal'
  import RequestActionReasonModal from './RequestActionReasonModal'

  export default {
    name: 'RequestActions',

    components: {
      RequestDetailModal,
      RequestActionReasonModal,
    },

    props: {
      selectedRequest: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      showDialog: false,
    }),

    methods: {
      closeMenu () {
        this.showDialog = false
        this.$refs.menu.isActive = false
      },
      updateReason (reason) {
        this.$emit('updateReason', reason)
      },
    },
  }
</script>
