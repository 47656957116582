<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header-v2
        title="Service Requests"
        icon="mdi-account-wrench"
        :padding="0"
      />
      <common-trs-card class="pa-2 pb-0 mb-2 mt-3">
        <service-request-list />
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import ServiceRequestList from './components/RequestList'

  export default {
    name: 'ServiceRequestIndex',

    components: {
      ServiceRequestList,
    },
  }
</script>
