<template>
  <div>
    <v-row class="no-gutters px-5 mt-4">
      <v-spacer />
      <common-trs-btn
        type="tertiary"
        icon
        @click="getAllServiceRequests"
      >
        <v-icon>mdi-refresh</v-icon>
      </common-trs-btn>
    </v-row>
    <v-row class="mt-md-0 mt-lg-0 px-5">
      <v-col class="pt-md-0 pt-lg-0">
        <common-trs-data-table
          :loading="loading"
          :headers="headers"
          :items="serviceRequestList"
          item-key="captable_srid"
          hide-default-footer
          class="share-table"
          :options.sync="pager"
          :server-items-length="pager.total"
        >
          <template v-slot:[`item.action_type`]="{item}">
            <span class="text-capitalize">{{ item.action_type }}</span>
          </template>
          <template v-slot:[`item.security_type`]="{item}">
            <span class="text-capitalize">{{ item.security_type }}</span>
          </template>
          <template v-slot:[`item.status`]="{item}">
            {{ CONSTS.SERVIC_REQUEST_STATUS_LABELS[item.status] }}
          </template>
          <template v-slot:[`item.created_on`]="{item}">
            {{ $date(item.created_on) }}
          </template>
          <template v-slot:[`item.action`]="{item}">
            <request-actions
              :selected-request="item"
              @updateReason="updateReason"
              @selectRequest="selectRequest"
            />
          </template>
        </common-trs-data-table>
        <v-divider />
        <common-trs-data-table-list-pager
          v-model="pager"
          class="ml-2"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import * as captableAdminService from '@/services/captable-admin-service'
  import RequestActions from './RequestActions'

  export default {
    name: 'ServiceRequestList',

    components: {
      RequestActions,
    },

    data: () => ({
      loading: false,
      serviceRequestList: [],
      selectedRequest: {},
      reason: '',
      requestAction: '',

      headers: [
        { text: 'Request Type', value: 'action_type', sortable: true },
        { text: 'Security Type', value: 'security_type', sortable: true },
        { text: 'Requested On', value: 'created_on', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'Action', value: 'action', sortable: false },
      ],
      pager: {
        itemsPerPage: 5,
        page: 1,
        total: 0,
      },
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      pager: {
        handler (val) {
          this.getAllServiceRequests()
        },
        deep: true,
        immediate: true,
      },
    },

    async created () {
      this.loading = true
      await this.getAllServiceRequests()
      this.loading = false
    },

    methods: {
      async getAllServiceRequests () {
        const resp = await captableAdminService.getServiceRequestList(this.captableId, { ...this.pager })
        this.serviceRequestList = resp?.results || []
        this.pager.total = Math.ceil(resp.count / this.pager.itemsPerPage)
      },

      async updateServiceRequest () {
        try {
          const data = {
            status: this.requestAction,
            details: this.selectedRequest.details,
          }
          data.details.reason = this.reason
          if (this.requestAction !== this.CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED) {
            await captableAdminService.approveServiceRequest(this.selectedRequest.action_type, this.selectedRequest.security_type, this.captableId, this.selectedRequest.details)
          }
          await captableAdminService.updateServiceRequestList(this.selectedRequest.captable_srid, data)
          this.$store.dispatch('app/message', { text: 'Request updated successfully.' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to update your service request.',
          })
        }
      },

      async updateReason (reason) {
        this.loading = true
        this.reason = reason
        await this.updateServiceRequest()
        await this.getAllServiceRequests()
        this.loading = false
      },

      selectRequest (request, action) {
        this.selectedRequest = request
        this.requestAction = action
      },
    },
  }
</script>
