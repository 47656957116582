<template>
  <v-dialog
    v-model="showDialog"
    :max-width="400"
    scrollable
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="{ on }">
      <slot
        name="activator"
        v-bind="{ on }"
      />
    </template>
    <common-trs-modal-card>
      <common-trs-modal-card-title>
        <common-trs-modal-card-title-content>
          Confirm
        </common-trs-modal-card-title-content>

        <common-trs-btn
          aria-label="Close"
          type="secondary"
          class="rounded-0"
          color="primary"
          size="large"
          icon
          text
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </common-trs-btn>
      </common-trs-modal-card-title>
      <common-trs-modal-card-text>
        <template>
          <div class="mb-5">
            <label class="trsText--text text-body-2 font-weight-medium mb-2">Please provide reason for {{ labelReasonText }}.</label>
            <common-input-text-field
              v-model="reason"
              label="Reason (Optional)"
              color="primary"
              clearable
              hide-details
              dense
              class="mt-4"
            />
          </div>
        </template>
      </common-trs-modal-card-text>
      <common-trs-modal-card-actions>
        <common-trs-btn
          class="mr-3 primary--text"
          type="tertiary"
          text
          @click="close"
        >
          Cancel
        </common-trs-btn>

        <common-trs-btn
          type="primary"
          text
          class="white--text"
          @click="confirm"
        >
          Confirm
        </common-trs-btn>
      </common-trs-modal-card-actions>
    </common-trs-modal-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'RequestActionReasonModal',

    props: {
      reasonType: {
        type: String,
        required: true,
        default: '',
      },
    },

    data: () => ({
      showDialog: false,
      reason: '',
      labelReasonText: '',
    }),

    watch: {
      reasonType: {
        handler (val) {
          if (val) {
            if (val === this.CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.APPROVED) {
              this.labelReasonText = 'approval'
            } else if (val === this.CONSTS.CAPTABLE_SERVICE_REQUEST_STATUS.REJECTED) {
              this.labelReasonText = 'rejection'
            }
          }
        },
        immediate: true,
        deep: true,
      },
    },

    methods: {
      close () {
        this.showDialog = false
        this.$emit('close')
      },
      confirm () {
        this.$emit('confirm', this.reason)
        this.close()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-dialog__content {
    z-index: 99999 !important;
  }
</style>
