var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"no-gutters px-5 mt-4"},[_c('v-spacer'),_c('common-trs-btn',{attrs:{"type":"tertiary","icon":""},on:{"click":_vm.getAllServiceRequests}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-row',{staticClass:"mt-md-0 mt-lg-0 px-5"},[_c('v-col',{staticClass:"pt-md-0 pt-lg-0"},[_c('common-trs-data-table',{staticClass:"share-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.serviceRequestList,"item-key":"captable_srid","hide-default-footer":"","options":_vm.pager,"server-items-length":_vm.pager.total},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"item.action_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.action_type))])]}},{key:"item.security_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.security_type))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.CONSTS.SERVIC_REQUEST_STATUS_LABELS[item.status])+" ")]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.created_on))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('request-actions',{attrs:{"selected-request":item},on:{"updateReason":_vm.updateReason,"selectRequest":_vm.selectRequest}})]}}],null,true)}),_c('v-divider'),_c('common-trs-data-table-list-pager',{staticClass:"ml-2",model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }